.portfolio-link {
    border: 0;
    background-color: aliceblue;
    color: black;
    margin-right:30px;
    margin-left: 20px;
}
.portfolio-link-selected {
    border: 0;
    background-color: #69bae9;
    color: white;
    margin-right:30px;
    margin-left: 20px;
    
}
.langLink{
    cursor: pointer;
}
.arBody{
    font-family: "Noto Kufi Arabic", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
    color: #000000;
    
    -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
    -webkit-text-size-adjust: 100%;
}
.arText{
    font-family: "Noto Kufi Arabic", sans-serif;
    font-size: larger;
    letter-spacing: 0;
}
.contentContainer {
    place-content: center;
    height: 250px;
    padding:15px;
}